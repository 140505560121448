import axios from 'axios'
import { getSession } from 'next-auth/client'

const getToken = async () => {
  const tok = await getSession()
  console.log(tok)
  return tok?.user?.image?.access_token
}


const obj = {
  fullName: () => {
    console.log(this.name)
  }
  /*doQuery: async () => {
    let token = await getToken()
    return await axios({
      url: `${process.env.GRAPHQL}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        query: `${this}`
      }
    })
  }*/
}


const api = {

  login: async (email) => {

    return await axios({
      url: `${process.env.GRAPHQL}`,
      method: 'post',
      data: {
        query: `
              mutation {
                Login (
                  username: "${email}"
                    
                ) {
                  status
                  access_token
                  user{
                    id
                    name
                    role
                    category {
                      id
                      slug
                      title
                      main_channel
                    }
                    group {
                      id
                      title
                    }
                    activity {
                      url
                    }
                  }
                }
              }
                `
      }
    })
  },

  getCategoryTalksByDay: async (category_id, to) => {
    // var token = await getToken()
    // console.log(token)
    return await axios({
      url: `${process.env.GRAPHQL}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${to}`
      },
      data: {
        query: `query {
          GetCategoryTalksByDay(category_id: ${category_id}) {
            id
            title
            main_channel
            
            custom_days {
              id
              title
              
              custom_talks {
                id
                title
                type
                starts_at
                ends_at
                
                custom_groups {
                  id
                  title
                  
                  custom_links {
                    id
                    title
                    url
                  }
                }
                
              }
              
            }
            
          }
        }`
      }
    })
  },
  getCategories: async (to) => {
    var token = await getToken()
    return await axios({
      url: `${process.env.GRAPHQL}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${to}`
      },
      data: {
        query: `query {
          GetCategoryTalksByDay(category_id:7) {
            id
            title
            main_channel
            
            custom_days {
              id
              title
              
              custom_talks {
                id
                title
                type 
                starts_at
                ends_at
                
                custom_groups {
                  id
                  title
                    
                  custom_links {
                    id
                    title
                    url
                  } 
                }
              }
              
            }
            
          }
        }`
      }
    })
  },
  getMessagesModerator: async (to_moderator, category_id, day_id, moderator_readed, token) => {

    return await axios({
      url: `${process.env.GRAPHQL}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        query: `
              query {
                messagesForModerator(
                  to_moderator: ${to_moderator}
                  category_id: ${category_id}
                  day_id: ${day_id}
                  moderator_readed: ${moderator_readed}
                  orderBy: [
                    {
                      field: "created_at"
                      order: DESC
                    }
                  ]
                ){
                  message
                  id
                  created_at
                  user{
                    id
                    name
                  }
                  category {
                      id
                      title
                    }
                }
              }
                `
      }
    })
  },

  getMessagesSpeaker: async (to_speaker, category_id, day_id, speaker_readed, token) => {
    return await axios({
      url: `${process.env.GRAPHQL}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        query: ` query {
          messagesForSpeaker(
            to_speaker: ${to_speaker}
            category_id: ${category_id}
            day_id: ${day_id}
            speaker_readed: ${speaker_readed}
            orderBy: [
              {
                field: "created_at"
                order: DESC
              }
            ]
          ){
            message
            id
            created_at
            user{
              id
              name
            }
            category {
                id
                title
              }
          }
        }`
      }
    })
  },


  sendMessage: async (token, message, day_id, category_slug) => {
    
    //console.log('a regular user has sente a message')
    return await axios({
      url: `${process.env.GRAPHQL}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        query: `
                mutation {
                    SendToModerator(
                          message: "${message}"
                          category_slug: "${category_slug}"
                          day_id: "${day_id}"
                    ) {
                      id
                      message
                    }
                  }
                `
      }
    })
  },

  sendAdminMessage: async (token, message, day_id, category_slug) => {

    //console.log( message, day_id, category_slug )

    return await axios({
      url: `${process.env.GRAPHQL}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        query: `
                mutation {
                  SendAdminToModerator(
                          message: "${message}"
                          category_slug: "${category_slug}"
                          day_id: "${day_id}"
                    ) {
                      id
                      message
                    }
                  }
                `
      }
    })
  },

  sendMessageToSpeaker: async (messageId, userId, token) => {
    return await axios({
      url: `${process.env.GRAPHQL}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        query: `
                mutation{
                    SendToSpeaker(
                      message_id: "${messageId}"
                      user_id: "${userId}"
                    ){
                      id
                      message
                    }
                  }
                `
      }
    })
  },
  markAsReadModerator: async (messageId, token) => {

    return await axios({
      url: `${process.env.GRAPHQL}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        query: `
                mutation{
                    MarkModeratorReaded( message_id: "${messageId}"){
                      id
                      
                    }
                  }
                `
      }
    })
  },


  markAsReadSpeaker: async (messageId, token) => {

    return await axios({
      url: `${process.env.GRAPHQL}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        query: `
                mutation{
                    MarkSpeakerReaded( message_id: "${messageId}"){
                      id
                      
                    }
                  }
                `
      }
    })
  },

  logout: async (token) => {
    var token = await getToken()
    return await axios({
      url: `${process.env.GRAPHQL}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        query: `mutation {
          logout {
            status
            message
          }
        }`
      }
    })
  },

  getCategoryBySlug: async (category_slug, token) => {
    //var token = await getToken()
    //console.log( 'token here', token )
    return await axios({
      url: `${process.env.GRAPHQL}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        query: `query {
          categoryBySlug(slug: "${category_slug}") {
            id
            title
            slug
            main_channel
            pusher_channel
          }
        }`
      }
    })
  },


  getCategory: async (category_id, to) => {
    var token = await getToken()
    return await axios({
      url: `${process.env.GRAPHQL}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${to}`
      },
      data: {
        query: `query {
          category(id: "${category_id}") {
            id
            title
            slug
            main_channel
            pusher_channel
          }
        }`
      }
    })
  },


  createTracking: async (category_id, day_id) => {
    var token = await getToken()
    return await axios({
      url: `${process.env.GRAPHQL}`,
      method: "post",
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        query: `mutation {
            CreateTracking(category_id: ${category_id} day_id: ${day_id})
          }`
      }
    })
  },



  getNow: async (token) => {
    //var token = await getToken()
    return await axios({
      url: `${process.env.GRAPHQL}`,
      method: "post",
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        query: `query {
          GetNow
        }`
      }
    })
  },


  updateTrackingQuery: (category_slug, day_id, milliseconds) => {

    return `mutation {
      UpdateAccumulatedTime (
            category_slug: "${category_slug}"
            day_id: "${day_id}"
            milliseconds: ${milliseconds}
        )
    }`
  },

  getCurrentTalkQuery: (category_id, day_id) => {
    return `query {
      GetCurrentTalk(
        category_id: "${category_id}"
        day_id: "${day_id}"
      ) {
        id
        title
        starts_at
        ends_at
        
        custom_groups {
          id
          title
          
          custom_links {
              id
              title
              url
          }
        }
      }
    }`
  },

  getCurrentTalk: async (category_id, day_id, token) => {
    return await axios({
      url: `${process.env.GRAPHQL}`,
      method: "post",
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        query: api.getCurrentTalkQuery(category_id, day_id)
      }
    })
  },

  fetchSwr: (token, query) => {
    return axios({
      url: `${process.env.GRAPHQL}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        query: `${query}`
      }
    }).then(res => res.data.data)
  },

  getHeaderCategories: async (token) => {
    return await axios({
      url: `${process.env.GRAPHQL}`,
      method: "post",
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        query: `query {
          GetHeaderCategories {
            id
            title
            slug
          }
        }
        `
      }
    })
  },

  getLastMessages: async (token, category_id, day_id) => {

    return await axios({
      url: `${process.env.GRAPHQL}`,
      method: "post",
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        query: `query {
          getLastMessages(
            first: 15
            orderBy: [
              {
                field: "created_at"
                order: DESC
              }
            ]
            category_id: ${category_id}
            day_id: ${day_id}
          ) {
            data {
              id
              message
              created_at
              user {
                id
                name
              }
            }
          }
        }
        `
      }
    })

  },

  getMessages: async (token, category_slug, day_id) => {
    return await axios({
      url: `${process.env.GRAPHQL}`,
      method: "post",
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        query: `query {
          GetMessages( category_slug: "${category_slug}", day_id: "${day_id}" ) {
            id
            message
            created_at

            user {
              id
              name
            }
          }
        }
        `
      }
    })
  },

  dayQuery: () => {
    return `query {
          day(id: 1) {
            id
            activity_status
          }
        }`
  },



  getRecap: async (token, day_id) => {
    return await axios({
      url: `${process.env.GRAPHQL}`,
      method: "post",
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        query: `query {
          getRecap(
            day_id: ${day_id}
          ) {
            id
            playback_id
          }
        }`
      }
    })
  },


  getRecaps: async (token) => {
    return await axios({
      url: `${process.env.GRAPHQL}`,
      method: "post",
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        query: `query {
          getRecaps {
            id
            
            day {
              id
              title
            }
          }
        }`
      }
    })
  },

}

export default api