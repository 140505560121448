import { useState } from 'react'
//import { Transition } from '@headlessui/react'

import HeaderLinks from './header/headerLinks.js'
import CloseButton from './header/closeButton.js'
import styles from '../../components/Common.module.css'

export default function Header({ session, categories }) {

    //categories = categories?.data?.data?.GetHeaderCategories

    const [isOpen, setIsOpen] = useState(false)

    return (
        <header>
            <nav className={"bg-gray-800 border-b border-gray-600 " + styles.menu }>
                <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-4">
                    <div className="flex items-center justify-between h-16">
                        <div className="flex items-center">
                            <div className="flex-shrink-0">
                                <img className="lg:block h-10 w-auto" src="/img/logo-grunenthal.svg" alt="Grünenthal Logo" />
                            </div>
                        </div>

                        {session && <div className="-mr-2 flex xl:hidden">
                            
                            <button onClick={() => setIsOpen(!isOpen)} className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white transition duration-150 ease-in-out" aria-label="Main menu" aria-expanded="false">
                                {!isOpen ?
                                    <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                                    </svg>
                                    :
                                    <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                }
                            </button>

                        </div>
                        }

                        {session && <div className="hidden xl:block">
                            <HeaderLinks session={session} categories={categories} />
                            </div>
                        }

                        {session && <div className="hidden xl:block">
                                <CloseButton session={session} />
                            </div>
                        }


                    </div>
                </div>

                {session && <div className={!isOpen ? 'hidden' : 'bg-gray-800 relative z-10 block xl:hidden'}>

                    <div className="pb-3 border-t border-gray-700">
                        <div className="mt-3 px-2">
                            <HeaderLinks session={session} categories={categories} />
                        </div>
                    </div>
                </div>
                }

            </nav>

        </header>
    )
}