import { signOut } from 'next-auth/client'
import api from '../../../pages/api/index.js'

export default function CloseButton({session}) {

    const logOut = async (  ) => {

        //signOut({ callbackUrl: `/` })
        //return

        try {
            let { data } = await api.logout(session.user.image.access_token)
            console.log(data)
            if (data.data.logout.status === 'TOKEN_REVOKED')
                signOut({ callbackUrl: `/` })

        } catch (error) {
            console.log(error)
        }
    }


    return (
        <div className="flex items-center justify-center xl:ml-3 xl:relative">
            <button onClick={logOut}
                className="text-gray-400 flex items-center justify-center mt-2 text-xs border-2 border-transparent rounded-full focus:outline-none focus:border-white transition duration-150 ease-in-out" aria-label="Logout" aria-haspopup="true">
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
                    Salir
            </button>
        </div>
    )

}