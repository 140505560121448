import Head from 'next/head'
// import {BASE_URL} from '../../src/api/constants'
import api from '../../pages/api/index.js'
import { getSession } from 'next-auth/client'
import { useEffect, useState } from 'react'
// import Zendesk from 'react-zendesk'
// const ZENDESK_KEY = process.env.ZENDESK_KEY

import Header from '../common/header.js'

export default function Layout({ children }) {

    const [session, setSession] = useState('')
    const [categories, setCategories] = useState('')

    useEffect(() => {
        getSessionForHeader()
    }, [])

    const getSessionForHeader = async () => {
        const sess = await getSession()
        setSession(sess)

        const categoriesQuery = await api.getHeaderCategories(sess?.user?.image?.access_token)
        setCategories(categoriesQuery)
    }

    const setting = {
        webWidget: {
            launcher: {
              chatLabel: {
                '*': 'Ayuda'
              }
            },
            offset: {
                horizontal: '70px',
                vertical: '0px',
                mobile: {
                  horizontal: '30px',
                  vertical: '0px'
                }
            }
        },
        position: { 
            horizontal: 'right', 
            vertical: 'top' 
        }
    };

    return (
        <>

            <Head>

                <meta charSet="utf-8" />
                {/* <link rel="apple-touch-icon" sizes="76x76" href="../assets/img/apple-icon.png"/> */}
                <link rel="icon" type="image/png" href="/img/favicon.png" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
                {/* <!-- Primary Meta Tags --> */}
                <title>JUNAVE 2021</title>
                {/* <meta name="title" content="Seminario en línea | Medical Beyond" />
    <meta name="description" content={description} /> */}
                {/* <!-- Open Graph / Facebook --> */}
                {/* <meta property="og:type" content="website"/>
    <meta property="og:url" content={`${BASE_URL}${slug}`}/>
    <meta property="og:title" content="Seminario en línea | Medical Beyond"/>
    <meta property="og:description" content={description} />
    <meta property="og:image" content="${BASE_URL}<?php echo $metaSlug; ?>/assets/img/<?php echo $metaImg; ?>.jpg" /> */}
                {/* <!-- Twitter --> */}
                {/* <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:url" content={`${BASE_URL}${slug}`} />
    <meta property="twitter:title" content="Seminario en línea | Medical Beyond" />
    <meta property="twitter:description" content={description} />
    <meta property="twitter:image" content="${BASE_URL}<?php echo $metaSlug; ?>/assets/img/<?php echo $metaImg; ?>.jpg" />
    <meta content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0, shrink-to-fit=no' name='viewport' /> */}
                {/* <!--     Fonts and icons     --> */}
                <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;800&amp;display=swap" rel="stylesheet" />

            </Head>
            <div className="font-sans">

                <Header session={session} categories={categories} />

                {/* <Zendesk zendeskKey={ZENDESK_KEY} {...setting} onLoaded={() => console.log('is loaded')} /> */}

                {children}

            </div>
        </>
    )
}
